$(document).ready(function() {

  // var setter = $('#section3').outerHeight();
  // var getter1 = $('#getter-1').height();
  // var getter2 = $('#getter-2').height();

  // var getter = getter1 + getter2 + 90 + 80;
  // var height = getter - 30 - (setter - getter);

  // $('#setter').css('min-height',height+'px');
  
  
  $('#totop').click(function(){
    $('html, body').animate({ scrollTop: 0 }, 3500);
  });
  
  var section1 = false;
  var section2 = false;

  
  $(window).scroll(function(e){
    var distancetofirstpage = $(".page").first().offset().top - $(window).scrollTop();
    if(distancetofirstpage < 0 && section1 == false) {
      $('#wrapper-headline').hide();
    } else if (distancetofirstpage > 0 && section1 == true){
      $('#wrapper-headline').show();
    }
  

    var distancetosection1 = $("#section1").offset().top - $(window).scrollTop();
    var distancetosection2 = $("#section2").offset().top - $(window).scrollTop();
    



    if(distancetosection1 <= 0 && section1 == false) {
      section1 = true;
      $("header").css("background-image","url('/images/cherry-blossom2.jpg')");
    } else if (distancetosection1 > 0 && section1 == true){
      section1 = false;
      $("header").css("background-image","url('/images/cherry-blossom.jpg')");
    } else if(distancetosection2 <= 0 && section2 == false) {
      section2 = true;
      $("header").css("background-image","url('/images/cherry-blossom3.jpg')");
    } else if(distancetosection2 > 0 && section2 == true) {
      section2 = false;
      $("header").css("background-image","url('/images/cherry-blossom2.jpg')");
    }
  });

});
